import react, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Screen from "./Screen";
import Chat from "./ChatPages/Chat";
import { SharedStateProvider, useSharedState } from "./UserContext";
import Login from "src/component/Login";
import AuthApi from "src/services/auth/index";
// import ChatSearch from "./ChatSearch";
import Guide from "./guide/index";
import Home from "./home";
import SearchTabs from "./ChatPages/SearchTabs";
import ChatDetailPage from "./ChatPages/ChatDetailPage";
import IntermediateInPage from "./component/IntermediateInPage";
import IntermediateOutPage from "./component/IntermediateOutPage";
import Graph from "./ChatPages/Graph";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
  useLocation,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Header from "src/ChatPages/Header";

import tj from "@itic-sci/teamjob";

tj.init({
  env: "dev",
  commonParams: {
    product: "细胞与基因图谱",
  },
});
// import "./mock";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const showHeader = [
  "/Chat",
  "/Home",
  "/ChatSearch",
  "/Screen",
  "/IntermediateOutPage",
  "/IntermediateInPage",
];

const AnimatedRoutes = () => {
  const [userState, setUserState, showLogin, setShowLogin] = useSharedState();

  const location = useLocation();
  let cls = "";
  if (location.pathname === "/IntermediateInPage") {
    cls = "slideIn";
  } else if (location.pathname === "/IntermediateOutPage") {
    cls = "slideOut";
  }
  useEffect(() => {
    AuthApi.userInfo({}).then((info: any) => {
      if (info?.code === 0) {
        setUserState(info.data);
      }
    });

    // tj.track({
    //   params: {
    //     // 上报参数
    //     event_id: "cellandgene_page",
    //   },
    // });
  }, []);

  return (
    <>
      {showHeader.includes(location.pathname) && <Header />}
      {/* {location.pathname === "/ChatSearch" && <ChatSearchHeader />} */}

      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames={cls}
          timeout={!!cls ? 638 : 0}
        >
          <Routes location={location}>
            <Route path="/" element={<Navigate to="/ChatSearch" />} />
            <Route path="/Chat" element={<Chat />} />
            <Route path="/ChatSearch" element={<Home />} />
            <Route path="/Guide" element={<Guide />} />
            {/* <Route path="/ChatSearch" element={<ChatSearch />} /> */}
            <Route
              path="/IntermediateInPage"
              element={<IntermediateInPage />}
            />
            <Route
              path="/IntermediateOutPage"
              element={<IntermediateOutPage />}
            />
            <Route path="/Screen" element={<Screen />} />
            <Route path="/ChatDetailPage" element={<ChatDetailPage />} />
            <Route path="/graph_detail" element={<Graph />} />
            <Route path="/SearchTabs" element={<SearchTabs />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      {showLogin && (
        <Login
          setLoginVisiable={setShowLogin}
          setUserInfo={setUserState}
          showLogin={showLogin}
        />
      )}
    </>
  );
};
root.render(
  <BrowserRouter>
    <SharedStateProvider>
      <AnimatedRoutes />
    </SharedStateProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
